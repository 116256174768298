<template>
  <div>
    <Header />
    <div class="page">
      <div class="Editing-materials">
        <right class="ri" />
        <div class="left">
          <div class="content">
            <router-view></router-view>
            <!-- <keep-alive>
              <router-view></router-view>
            </keep-alive> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
import Right from "@/components/myyezai/right";
import local from "@/api/common/local.js";
import { subTk } from "@/api/member/member.js";
import { infoefresh } from "@/api/login/login.js";
export default {
  components: {
    Header,
    Right,
    Footer,
  },

  methods: {
    async tk() {
      const { code, data } = await subTk();
      let Data = local.get("access_token");
      await infoefresh(Data);
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  padding-top: 100px;
  background-color: #fafafaff;
  // background-color: #fff;
  display: flex;
  justify-content: center;
  .Editing-materials {
    width: 1200px;
    margin-bottom: 50px;
    display: flex;
    //测试
    // padding-top: 100px;
    // box-sizing: border-box;
    // display: flex;
    //测试
    .ri {
      max-height: 787px;
      // min-height: 787px;
      // height: 0;
    }
    .left {
      width: 880px;
      background-color: #fff;
      .content {
        padding: 0px 20px;
        //1
        // margin-left: 30px;
        // background-color: #fff;
        // margin-bottom: 70px;
      }
    }
  }
}
</style>