<template>
  <div class="about">
    <div class="portrait">
      <img class="portrait" :src="avatar || src" alt="" @click="xiangce" />
      <p v-if="avatarAppStatus === '2'">审核中</p>
    </div>
    <!-- 会员账号昵称 -->
    <p style="margin-top: 12px; font-size: 18px; font-weight: bold">
      {{ nickName }}
    </p>
    <!-- 徽章 -->
    <div class="badge">
      <img :src="iSmember == 0 ? huiyuan : Fhuiyuan" alt="" @click="smsb(0)" />
      <img
        :src="realAuthStatus == 2 ? Frenzheng : renzheng"
        alt=""
        @click="smsb(2)"
      />
    </div>
    <!-- 编辑资料进程 -->
    <div class="infoPercent">
      <div></div>
      <div>
        <p class="infoPercent-bfb">
          <span class="bfb-y">资料完整度{{ Percentage + "%" }}</span>
          <span class="bfb-yl" @click="yl">预览资料</span>
        </p>
        <div class="progress">
          <el-progress :percentage="Percentage" :format="format"></el-progress>
        </div>
      </div>
    </div>
    <!-- 信息列表 -->
    <ul ref="list">
      <li class="ref">
        <div class="l" ref="name" @click="fn(1)">
          个人资料<i v-show="a" class="el-icon-arrow-right"></i
          ><i v-show="!a" class="el-icon-arrow-down"> </i>
        </div>
        <div v-show="!a" class="z">
          <ul>
            <li ref="one">
              <div @click="insurance(1)">基本资料</div>
            </li>
            <li>
              <div @click="insurance(2)">详细资料</div>
            </li>
            <li>
              <div @click="insurance(3)">工作生活</div>
            </li>
          </ul>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="insurance(4)">
          内心独白<i class="el-icon-arrow-right"></i>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="insurance(5)">
          兴趣爱好<i class="el-icon-arrow-right"></i>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="insurance(6)">
          择偶条件<i class="el-icon-arrow-right"></i>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="insurance(7)">
          我的认证<i class="el-icon-arrow-right"></i>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="insurance(8)">
          我的相册<i class="el-icon-arrow-right"></i>
        </div>
      </li>
      <li class="ref">
        <div class="l" @click="fn(2)" ref="xt">
          系统设置
          <i v-show="b" class="el-icon-arrow-right"></i>
          <i v-show="!b" class="el-icon-arrow-down"></i>
        </div>
        <div v-show="!b" class="z">
          <ul>
            <li ref="two">
              <div @click="insurance(9)">权限设置</div>
            </li>
            <li ref="">
              <div @click="insurance(10)">修改密码</div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import local from "@/api/common/local.js";
import { infocur, infoPerfection } from "@/api/login/login.js"; //infoefresh
import { allAppStatus } from "@/api/member/member.js"; //infoefresh
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  data() {
    return {
      //默认头像
      src: require("@/assets/images/message/tou.png"),
      ManSrc: require("@/assets/images/message/manTou.png"),
      WomanSrc: require("@/assets/images/message/womanTou.png"),
      sex: "",
      isAvatar: "",
      a: true,
      b: true,
      c: true,
      num: "",
      avatar: "",
      isActive: false,
      nickName: "",
      Percentage: 0,
      idcard: "",
      iSmember: "",
      realAuthStatus: "",
      huiyuan: require("@/assets/images/tubiao/yezai_member_auth_bb.png"),
      Fhuiyuan: require("@/assets/images/tubiao/yezai_member_auth_aa.png"),
      renzheng: require("@/assets/images/tubiao/yezai_auth_a.png"),
      Frenzheng: require("@/assets/images/tubiao/yezai_auth_aa.png"),
      avatarAppStatus: "3",
    };
  },
  created() {
    this.g();
    this.infoPerfectionLL();
  },
  mounted() {
    //优化:
    // const UrlConditions = {
    //   "/n/user/_baseInfo/left": () => {
    //     this.a = !this.a;
    //     this.$refs.name.classList.add("a");
    //     this.$refs.one.classList.add("active");
    //   },
    // };

    //end

    //根据显示样式
    if (this.$route.path.indexOf("/n/user/_baseInfo/left") >= 0) {
      this.a = !this.a;
      this.$refs.name.classList.add("a");
      this.$refs.one.classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_deta") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      this.a = !this.a;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      this.$refs.name.classList.add("a");
      list[2].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_workLife") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      this.a = !this.a;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      this.$refs.name.classList.add("a");
      list[3].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_heartWord") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      // this.a = !this.a;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      list[4].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_interest") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      list[4].classList.remove("active");
      list[5].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_spousePreference") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      list.forEach((item) => {
        item.classList.remove("active");
      });
      list[6].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_auth") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      list.forEach((item) => {
        item.classList.remove("active");
      });
      list[7].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_album") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      // this.a = false;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      list[8].classList.add("active");
      // /n/user/_avatar
    } else if (this.$route.path.indexOf("/n/user/_avatar") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      // this.a = false;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      list[8].classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_setting/left") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      list.forEach((item) => {
        item.classList.remove("active");
      });
      this.b = !this.b;
      this.$refs.xt.classList.add("a");
      this.$refs.two.classList.add("active");
    } else if (this.$route.path.indexOf("/n/user/_password") >= 0) {
      let list = this.$refs.list.getElementsByTagName("li");
      this.b = !this.b;
      list.forEach((item) => {
        item.classList.remove("active");
      });
      this.$refs.xt.classList.add("a");
      list[11].classList.add("active");
    }
  },
  methods: {
    //跳转相册
    xiangce() {
      this.$router.push({ path: "/n/user/_album" });
    },
    //图片
    smsb(i) {
      if (i == 0) {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else if (i == 1) {
        this.$router.push({ path: "/n/user/_auth" });
      }
    },
    //用户信息百分比
    async infoPerfectionLL() {
      let Data = local.get("access_token");
      const { code, data } = await infoPerfection(Data);
      if (code == 0) {
        this.Percentage = parseInt(data);
      }
    },
    //预览资料
    yl() {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: this.idcard,
        },
      });
      window.open(rel.href, "_blank");
    },
    // 跳转
    insurance(id) {
      //直接调用$router.push 实现携带参数的跳转
      if (id == 1) {
        this.$router.push({
          path: `/n/user/_baseInfo/left`,
        });
      } else if (id == 2) {
        this.$router.push({
          path: `/n/user/_deta`,
        });
      } else if (id == 3) {
        this.$router.push({
          path: `/n/user/_workLife`,
        });
      } else if (id == 4) {
        this.$router.push({
          path: `/n/user/_heartWord`,
        });
      } else if (id == 5) {
        this.$router.push({
          path: `/n/user/_interest`,
        });
      } else if (id == 6) {
        this.$router.push({
          path: `/n/user/_spousePreference`,
        });
      } else if (id == 7) {
        this.$router.push({
          path: `/n/user/_auth`,
        });
      } else if (id == 8) {
        this.$router.push({
          path: `/n/user/_album`,
        });
      } else if (id == 9) {
        this.$router.push({
          path: `/n/user/_setting/left`,
        });
      } else if (id == 10) {
        this.$router.push({
          path: `/n/user/_password`,
        });
      }
    },
    // 编辑信息百分比
    format() {
      return `${this.Percentage}%`;
    },
    //点击改变样式
    fn(num) {
      console.log(num);
      if (num == 1) {
        this.a = !this.a;
        this.b = true;
        console.log("---", num);
      } else if (num == 2) {
        this.b = !this.b;
        console.log("-==-", num);

        this.a = true;
      }
    },
    //根据路由()
    getPath() {
      let list = this.$refs.list.getElementsByTagName("li");
      if (this.$route.path.indexOf("/n/user/_baseInfo/left") >= 0) {
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[1].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.add("a");
      } else if (this.$route.path.indexOf("/n/user/_deta") >= 0) {
        list.forEach((item) => {
          item.classList.remove("active");
        });
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.add("a");
        list[2].classList.add("active");
      } else if (this.$route.path.indexOf("/n/user/_workLife") >= 0) {
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[3].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.add("a");
      } else if (this.$route.path.indexOf("/n/user/_heartWord") >= 0) {
        // this.a = !this.a;
        this.a = true;
        this.b = true;
        this.$refs.name.classList.remove("a");
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[4].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_interest") >= 0) {
        // list[4].classList.remove("active");
        this.a = true;
        this.b = true;
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[5].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_spousePreference") >= 0) {
        this.a = true;
        this.b = true;
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[6].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_auth") >= 0) {
        this.a = true;
        this.b = true;
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[7].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_album") >= 0) {
        this.a = true;
        this.b = true;
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[8].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_avatar") >= 0) {
        this.a = true;
        this.b = true;
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[8].classList.add("active");
        this.$refs.xt.classList.remove("a");
        this.$refs.name.classList.remove("a");
      } else if (this.$route.path.indexOf("/n/user/_setting/left") >= 0) {
        this.a = true;
        this.$refs.xt.classList.add("a");
        this.$refs.name.classList.remove("a");
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[10].classList.add("active");
      } else if (this.$route.path.indexOf("/n/user/_password") >= 0) {
        this.a = true;
        this.$refs.xt.classList.add("a");
        this.$refs.name.classList.remove("a");
        list.forEach((item) => {
          item.classList.remove("active");
        });
        list[11].classList.add("active");
      }
    },
    //会员是否基础信息
    async g() {
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        this.nickName = data.user.nickName;
        this.avatar = ossL(data.user.avatar);
        this.idcard = data.user.id; //用户id
        this.iSmember = data.user.isVipMember; //是否会员
        this.realAuthStatus = data.user.realAuthStatus; //是否实名认证
        this.isAvatar = data.user.isAvatar;
        this.sex = data.user.sex;
      }

      const D = await allAppStatus();
      if (D.code == 0) {
        console.log(D.data);
        this.avatarAppStatus = D.data.avatarAppStatus;
      }
    },
  },
  watch: {
    $route: "getPath",
  },
};
</script>

<style lang="less" scoped>
.active,
.a {
  color: #fd686eff;
}

.about {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
  box-sizing: border-box;
  width: 300px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-right: 20px;
  background-color: #fff;

  .portrait {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    p {
      position: absolute;
      bottom: 0px;
      width: 100%;
      text-align: center;
      color: #fff;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .badge {
    // margin-top: 8px;
    // margin-bottom: 30px;
    // height: 16px;
    // width: 100%;
    // justify-content: center;
    // display: flex;
    // .hui {
    width: 100%;
    // height: 16px;
    margin-top: 8px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    img {
      margin-left: 6px;
      margin-right: 6px;
      cursor: pointer;
    }

    // }
    // .FLAG {
    //   display: flex;
    //   width: 16px;
    //   height: 16px;
    //   background: url("../../assets/images/tubiao/yezai_gray.png") no-repeat;
    // }
  }

  .infoPercent {
    margin-bottom: 40px;

    .progress {
      position: relative;

      /deep/ .el-progress__text {
        position: absolute;
        top: -21px;
        left: 64px;
        display: none;
      }

      /deep/ .el-progress-bar__inner {
        background-color: #fd686eff;
      }

      /deep/ .el-progress-bar__outer {
        width: 240px;
        height: 7px;
      }
    }

    .infoPercent-bfb {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      .bfb-y {
        color: #666;
        font-size: 14px;
      }

      .bfb-yl {
        color: #fd686e;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  ul {
    li.active {
      color: #fd686eff;
      // .l{
      //   color: #fd686eff;
      // }
    }

    li {
      margin: 20px 0px;
      cursor: pointer;
      color: #666;

      .l {
        width: 300px;
        display: flex;

        font-weight: bold;
        justify-content: space-around;
      }

      .z {
        display: flex;
        // justify-content: center;
        margin-left: 80px;
      }
    }
  }
}

/deep/ .el-progress-bar__inner {
  background-color: #fd686e;
  height: 8px !important;
}

/deep/ .el-progress-bar__outer {
  height: 8px !important;
}
</style>
